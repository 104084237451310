@import 'node_modules/sass-rem/_rem.scss';
@import '../../styles/scss/variables';

.ap-page-get-started {
    width: calc(100% - rem(308px));
    @media screen and (min-width: 1441px) {
      width: rem(845px);
    }
    .ap-breadcrumb {
      margin-top: rem(20px);
    }
    .ap-component-wrapper {
      .ap-component-name {
        margin-bottom: $spacing-5;
      }
    }
    .ap-accordion .ap-accordion-content .ap-accordion-wrapper{
      padding-bottom: rem(12px);
    }
    // .ap-accordion-title.ap-font-medium {
    //   margin-left: rem(8px);
    // }
    .ap-accordion:hover {
      background-color: unset;
    }
    .ap-panel-body {
      padding-left: rem(24px);
      padding-right: rem(24px);
      padding-bottom: rem(24px);
    }
    @media screen and (max-width: 1280px) {
        width: calc(100% - rem(228px));
    }
    .pattern-content .ap-faqs.has-anchor .ap-page-content {
        width: 100%;
    }
  .ap-page-section-desc {
    font-size: $typography-text-size-3;
    line-height: $typography-line-height-3;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: rem(-0.475px);
    color: $color-text-body;
    word-wrap: break-word;

    &.desc-1 {
      margin-top: rem(40px);
    }

    &.desc-2 {
      margin-top: $spacing-6; // 20px
      margin-bottom: rem(40px);
    }

    &.desc-3 {
      margin-bottom: $spacing-4; // 12px
    }

    &.notification-panel {
      display: flex;
      column-gap: $spacing-3;
      margin-top: $spacing-6;
      padding: $spacing-3 $spacing-4 $spacing-3 $spacing-4;
      color: $color-text-body;
      background-color: $color-background-hover-selected;
      line-height: $typography-line-height-4;
      border-radius: $border-radius-2; // 4px
    }

    &.figma-des {
      margin-top: $spacing-6; // 20px
    }
  }

  .text-bold {
    font-weight: $font-weight-2;
  }
  .ap-panel.manual-content {
    .ap-panel-body{
      padding-left: rem(20px);
      padding-right: rem(20px);
      padding-bottom: rem(20px);
    }
  }
  .ap-panel-content.step-content{
    padding-top: rem(24px);
    &.manual-content{
      padding-top: rem(20px);
    }
    .text-bold{
        font-weight: 500;
    }
    li {
        list-style-type: none;
        &:not(:last-of-type){
          margin-bottom: rem(20px);
        } 
        &.point-b{
          margin-bottom: rem(8px);
        } 
    }
    li+ul {
        transform: translateX(rem(-20px));
    }
  }

  .ap-page-card {
    border-radius: $border-radius-3;
    box-shadow: $box-shadow-1;
    background-color: $color-background-container;
    padding: $spacing-6;
    font-size: $typography-text-size-3;
    line-height: $typography-line-height-3;
    transition: box-shadow background-color $transition-0;

    &.clickable {
      height: rem(116px);
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &:hover {
        box-shadow: $box-shadow-2;
        // background-color: $color-background-hover;

        .card-top-left,
        .card-top-right {
          color: $color-text-primary !important;
        }

        

        .ap-page-card-svg {
          color: $color-background-icon-primary !important;
        }

      }

      .card-top {
        font-size: $typography-text-size-4;
        font-weight: $font-weight-2;
        line-height: $typography-line-height-3;
        display: flex;
        justify-content: space-between;

        .card-top-left {
          max-width: rem(280px);
          color: $color-text-heading;
          transition: color $transition-0;
        }
        .icon-wrapper{
            background-color: $color-background-container-alt;
            width: rem(76px);
            height: rem(76px);
            border-radius: $border-radius-3;
            display: flex;
            justify-content: center;
            align-items: center;
            .Appkit4-icon{
                color: $color-text-primary;
            }
        }

        .card-top-right {
          display: flex;
          column-gap: $spacing-2;
          color: $color-text-heading;
          transition: color $transition-0;
        }

        .ap-page-card-svg {
          font: $typography-6 'PwC Helvetica Neue';
          width: 48px;
          height: 48px;
        }

        .ap-page-card-icon {
          font: $typography-6 'PwC Helvetica Neue';
          width: rem(48px);
          height: rem(48px);
          color: $color-text-heading;
        }
      }

      .card-bottom {
        color: $color-text-body;
        margin-right: rem(-2px);
        font-weight: 400;
        margin-top: rem(4px);
        font-size: rem(16px);
        max-width: rem(253px);
      }
      .ap-link {
        font-weight: 400;
        font-size: rem(16px);
      }

    //   @media screen and (max-width: 1448px) {
    //     .card-bottom {
        //   overflow: hidden;
        //   text-overflow: ellipsis;
        //   white-space: nowrap;
    //       @media screen and (min-width: 1024px) {
    //         max-width: rem(262px);
    //       }
    //     }
    //   }

      @media screen and (max-width: 1023px) {
        row-gap: $spacing-2;

        .card-top {
          row-gap: $spacing-6;
        }
      }
    }

    &.designers {
      // height: rem(140px);
      margin-top: $spacing-7;

      .ap-page-card-subtitle {
        margin-bottom: $spacing-2;
      }
    }

    &.developers {
      margin-top: $spacing-6;

      .ap-page-card-section:not(:last-child) {
        margin-bottom: $spacing-6;
      }

      .ap-page-card-subtitle {
        margin-bottom: rem(6px);
      }
    }

    &.card-2 {
      margin-top: $spacing-4;

      .ap-page-card-title {
        margin-bottom: rem(6px);
      }
    }

    .ap-page-card-title {
      color: $color-text-heading;
      font-size: $typography-text-size-4;
      font-weight: $font-weight-2;
      line-height: $typography-line-height-3;
    }

    .ap-page-card-subtitle {
      color: $color-text-heading;
      font-weight: $font-weight-2;
    }

    .ap-page-card-content {
      color: $color-text-body;
    }

    .ap-page-card-section-table {
      margin: 0 calc(-1 * $spacing-6) calc(-1 * $spacing-4); // 0 -20px -12px

      ::ng-deep .ap-table {
        box-shadow: none;

        tr {
          td {
            min-width: rem(130px);
            &:first-child {
              width: rem(250px);
  
              .code {
                display: flex;

                span {
                  padding: 0 $spacing-3; // 0 8px
                  border-radius: $border-radius-2; // 4px
                  background-color: $color-background-selected;
                }
              }
            }
          }
          &:hover {
            .code span {
              background-color: $color-background-hover-selected !important;
            }
          }
        }
      }
    }
  }

  .ap-page-card-container {
    margin-top: $spacing-6;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: $spacing-7;
    @media screen and (max-width: 1023px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        row-gap: $spacing-4;
    }
    @media screen and (min-width: 1025px) and (max-width: 1439px) {
        column-gap: $spacing-3;
        .ap-page-card {
          padding: $spacing-4;
        }
    }
  }

  ::ng-deep .ap-page-designer-tabset {
    .ap-tabset-container .ap-tabset-toggle-wrapper.filled {
      background-color: $color-background-container !important;
    }
  }

}

@media screen and (min-width: 900px) {
  .ap-container.home.getting-started:has(.ap-page-get-started) {
      max-width: unset!important;
  }
}
@media screen and (min-width: 1441px) {
  .ap-container.home.getting-started:has(.ap-page-get-started) {
      width: rem(1160px);
      // margin-left: 0;
      // margin-right: 0;
      // @media screen and (min-width: 1550px) {
      //   padding-left: 0;
      //   padding-right: 0;
      // }
  }
  .ap-container.licensing:has(.ap-page-get-started) {
    width: rem(1160px);
  }
}

::ng-deep .ap-page-card {
  .ap-tabset-container {
    row-gap: $spacing-6;

    .ap-tabset-toggle {
      padding: $spacing-2 $spacing-7 !important;
    }
  }
}


::ng-deep .ap-modal {
  .modal-library-title {
    padding: $spacing-2 $spacing-3;
    color: $color-text-body;
    background-color: $color-background-selected;
    border-radius: $border-radius-2;
    height: rem(28px);
    font-size: $typography-text-size-2;
    line-height: $typography-line-height-2;
  }

  .modal-library-options {
    list-style: none;
    padding: 0;
    margin-top: $spacing-4;
    display: grid;
    row-gap: $spacing-3;
  }
}

.ap-component-wrapper.no-top-bottom {
    margin-top: 0;
    margin-bottom: 0;
}
.ap-page-get-started{
    .ap-accordion-group-container .ap-accordion-toggle {
        padding-left: rem(4px)!important;
    }
    .ap-accordion-content.ap-accordion-cursor-default {
        padding-left: rem(4px);
        padding-right: rem(4px);
        &:not(.hidden){
          padding-left: rem(12px);
          padding-right: rem(12px);
        }
    }
    .ap-accordion-text {
        width: 100%;
        &.text-title {
            font-weight: 500;
        }
        &.panel-content{
            background-color: $color-background-hover;
            padding: rem(12px);
            border-radius: $border-radius-3;
            display: flex;
            flex-direction: column;
            div:first-of-type{
                margin-bottom: rem(4px);
                font-weight: 500;
            }
            li{
                list-style-type: initial;
                transform: translateX(rem(22px));
                margin-top: rem(16px);
                width: calc(100% - rem(20px));
                // &:not(:first-of-type){
                //     margin-top: rem(8px);
                // }
                span:first-of-type {
                  font-weight: 500;
                }
            }
        }
    }
}

.category-card-panel-wrapper {
    background-color: $color-background-container;
    padding: rem(20px);
    border-radius: $border-radius-3;
    color: $color-text-body;
    .ap-component-name.anchor-target{
      margin-bottom: rem(12px);
    }
    .category-card-panel {
        padding: rem(16px);
        width: rem(762px);
        margin-top: $spacing-4;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: $spacing-5;
        border-radius: rem(8px);
        @media screen and (min-width: 1025px) and (max-width: 1439px) {
          gap: $spacing-4;
        }
        background-color: $color-background-container-alt;
        @media screen and (max-width: 768px) {
            grid-template-columns: repeat(1, 1fr);
        }
        .category-card {
            padding: rem(16px);
            border-radius: $border-radius-3;
            background-color: $color-background-container;
            div:first-child {
                font-weight: 500;
            }
        }
    }
}

.ap-page-get-started {
    .ap-page {
        width: 100%;
        .category-card-panel{
            width: 100%;
        }
    }
    .ap-pattern-faq-list .ap-pattern-faq-section:not(:first-of-type) {
      margin-top: $spacing-4;
  }
  
    .ap-pattern-faq-list .ap-pattern-faq-content {
      max-width: 100%;
      padding: 0;
      border-radius: $border-radius-3;
      box-shadow: $box-shadow-1;
      background-color: $color-background-container;
  }

  .ap-pattern-faq-list .ap-accordion-group-container .ap-accordion-content .ap-accordion-text {
    margin-left: unset;
  }

    .ap-pattern-page-anchor {
      color: $color-text-body;
      width: rem(246px);
      position: fixed;
      top: rem(84px);
      right: 0;
  
      &.anchor-list {
        margin-left: 0;
        margin-right: rem(40px);
      }
  
      &.hidden {
        display: none;
      }
  
      &.fixed {
        position: fixed;
        top: 0;
        right: 0;
        margin-top: rem(80px);
        margin-right: $spacing-6;
      }
  
      .anchor-trigger {
        height: $height-3;
        font-size: $typography-text-size-3;
        line-height: $typography-line-height-3;
        letter-spacing: rem(-0.4px);
        display: inline-block;
        height: 100%;
  
        &:hover {
          text-decoration: underline;
        }
  
        &.selected {
          font-weight: $font-weight-2;
          color: $color-text-heading !important;
        }
  
        .Appkit4-icon:first-child {
          margin-right: rem(2px);
        }
      }
  
      ul {
        padding-left: 0;
        margin-top: 0;
  
        li {
          line-height: $typography-line-height-3;
          cursor: pointer;
          list-style-type: none;
  
          &:not(:first-child) {
            margin-top: $spacing-3;
          }
  
          a {
            color: $color-text-body;
  
            &:focus-visible {
              outline: auto rem(5px) -webkit-focus-ring-color;
              box-shadow: none;
            }
          }
        }
      }
  
      .ap-pattern-page-anchor-heading {
        font-weight: $font-weight-2;
        line-height: $typography-line-height-4;
        font-size: $typography-text-size-4;
        margin-bottom: var(--spacing-3);
        color: $color-text-heading;
  
        &:focus-visible {
          outline: auto rem(5px) -webkit-focus-ring-color;
          box-shadow: none;
        }
  
        span:first-child {
          font-size: $typography-text-size-3;
        }
      }
    }
}

@media screen and (max-width: 1024px) {
    .ap-page-get-started {
        width: calc(100% - rem(280px));
    }
}

.ap-pattern-faq-content.getting-started{
    .ap-accordion.expanded {
        &:hover{
            background-color: unset;
        }
    }
}


@media screen and (max-width: 1427px) {
    .ap-page-get-started {
        width: 100%;
        .ap-pattern-page-anchor.anchor-list {
            display: none;
        }
    }
}
[data-mode='dark'] {
  .ap-page-get-started .ap-page-card.clickable .card-top .icon-wrapper {
    background-color: $color-background-hover-selected;
  }
  .category-card-panel{
    background-color: $color-background-default;
    .category-card{
      background-color: $color-background-hover-selected;
    }
  }
}
@media screen and (min-width: 769px) and (max-width: 1439px) {
  .ap-container.home.getting-started {
      max-width: 100%!important;
  }
}
@media screen and (max-width: 768px) {
  .ap-container.home:not(.getting-started):has(.ap-page-help-center-welcome) {
      padding-left: var(--ap-gutter-x, 0.5rem)!important;
  }
}
.dashboard-wrapper-container .outletcontainer:has(.ap-container.home.getting-started) {
  justify-content: unset;
}

.no-space-list {
  list-style-type: decimal;
  padding-left: 0;
  margin-left: 0;
}