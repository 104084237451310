@import '@appkit4/styles/appkit.min.css';
@import '@appkit4/react-components/dist/styles/appkit4-react.min.css';
@import '@appkit4/react-text-editor/dist/appkit4-react-texteditor.min.css';

@import 'node_modules/sass-rem/_rem.scss';
@import './scss/variables';
@import './scss/sharedStyles';

@font-face {
  font-family: 'PwC Helvetica Neue', sans-serif;
  font-style: normal;
  src: url('./fonts/800da3b0-675f-465f-892d-d76cecbdd5b1.woff2') format('woff2');
  font-weight: 700;
}

html {
  font-size: 16px; // for rem use
}

body {
  margin: 0;
  box-sizing: border-box;
  font-family: 'PwC Helvetica Neue', sans-serif;
  background-color: $color-background-default;
  // overflow: hidden;
}

.ap-header-heading {
  padding-top: 8px;
  padding-bottom: 8px;
}

@media (min-width: 900px) {
  .ap-container {
    max-width: 900px;
  }
}
@media (min-width: 1440px) {
  .ap-container {
    max-width: 1440px;
  }
}

.home-addProductModal {
  .ap-modal-body {
    max-height: rem(717px) !important;
    min-height: rem(510px) !important;
  }
  .ap-modal-footer-customize {
    width: 100%;
  }
}
.settings-accessModal {
  .ap-modal-body {
    min-height: unset !important;
  }
  .ap-modal-footer {
    padding: $spacing-6 !important;
  }

  .ap-search-list {
    max-height: rem(200px);
    overflow-y: auto;
  }
}

.settings-basicInfoModal {
  .ap-modal-body,
  .ap-modal-footer {
    padding-top: $spacing-6;
  }
  .ap-modal-footer-customize {
    width: 100%;
  }
}

.authTypeModal,
.stepperModal {
  &:has(.stepperModal-authTypeModal) {
      .ap-modal-title {
        display: none;
      }
    }
  .ap-modal-body {
    margin-top: $spacing-6;
    font: $typography-body;
    font-weight: $font-weight-1 !important;
    color: $color-text-body;
    letter-spacing: rem(-0.4px);
  }

  .ap-modal-footer {
    padding: $spacing-6 !important;

    .ap-modal-footer-customize {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      button {
        margin-left: $spacing-3;
      }
    }
  }
}

.authTypeModal {
  .ap-modal-footer-customize:not(:has(.cancel-without-saving)) {
    justify-content: center !important;
  }
}

.scanInprogressModal {
  width: rem(800px) !important;
  .ap-modal-footer {
    width: 100%;
  }
  .ap-modal-body {
    max-height: 100% !important;
  }
}

.scanPartCompleted {
  .ap-modal-footer-customize {
    width: 100%;
    justify-content: center;
    button:last-child {
      margin-left: $spacing-4;
    }
  }
}

.scanPartCompleted,
.scanCompleteModal,
.scanCouldnNotBeCompleted,
.scanTechnicalIssue {
  .ap-modal-footer {
    padding-top: $spacing-6;
  }
  .ap-modal-footer-customize {
    width: 100%;
    justify-content: center;
  }
}

.reRunScanModal {
  width: rem(800px) !important;
  .ap-modal-body {
    max-height: fit-content;
  }
  .ap-modal-footer {
    padding: $spacing-6 !important;
    .ap-modal-footer-customize {
      width: 100%;
    }
  }
}

.editPageToScanModal {
  width: rem(768px) !important;
  .ap-modal-body {
    max-height: fit-content;
  }
  .ap-modal-footer {
    padding: $spacing-6 !important;
  }
}

.editOtherSettingsModal {
  width: rem(540px) !important;
  .ap-modal-footer {
    padding-top: $spacing-6;
  }
}
.editAccessRequirementsModal {
  .ap-modal-footer {
    padding: $spacing-6 !important;

    .ap-modal-footer-customize {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .editAccessRequirementsModal-footer-rf {
        margin-left: rem(5px);
        span::before {
          content: '';
          background-color: #c52b19;
          display: inline-block;
          width: $spacing-2;
          height: $spacing-2;
          border-radius: $spacing-1;
          position: relative;
          bottom: $spacing-2;
          right: $spacing-2;
        }
      }

      div {
        display: flex;
        align-items: center;
      }
    }
  }
}

.restrictedAccessModal {
  .ap-modal-footer {
    padding-top: $spacing-6;

    .ap-modal-footer-customize {
      justify-content: center;
    }
  }
}
