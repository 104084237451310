@import 'node_modules/sass-rem/_rem.scss';
@import '../../styles/scss/variables';
.dashboard-wrapper-container {
  height: 100%;
  display: flex;
  
  &.homePage {
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
  }

  .welcome {
    display: flex;
    width: 100%;
    height: calc(100vh - rem(114px));
    justify-content: center;
    align-items: center;
  }

  .sideBarNavigation {
    display: inline-block;
    height: 100vh;
    // overflow-y: auto;
    .ap-navigation-wrapper .ap-nav-item.nav-with-child.nav-item-expanded > .nav-item-child .prefix-content + .itemContent {
      padding-left: rem(8px);
    }
    .ap-navigation-wrapper {
      border-radius: unset;
    }
  }

  .outletcontainer-collapsed,
  .outletcontainer {
    // height: 100vh; //For scroll
    display: flex;
    justify-content: center;
    // overflow-y: auto;
    background-color: $color-background-default;
    margin-left: rem(280px);
  }
  .outletcontainer-collapsed {
    width: calc(100vw - rem(72px));
  }
  .outletcontainer {
    width: calc(100vw - rem(280px));
    &.resource{
      width: 100vw;
    }
    //for floating menu
    &:not(.resource) {
      width: calc(100vw - rem(76px));
      margin-left: rem(76px);
      .issuesContainer {
        width: 100%;
      }
    }
  }

  .itemFooterContent {
    display: flex;
    padding-left: rem(40px);
    padding-right: rem(40px);
    padding-top: rem(64px);
    flex-direction: column;
    justify-content: space-between;
    // height: 100vh; //For scroll
    width: 100%;
  }

  .content {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  &.homePage{
    .outletcontainer{
      width: unset;
      margin-left: rem(280px)!important;
    }
  }

  //maybe do not need the footer
  // .footer {
  //   margin-left: rem(40px);
  //   margin-bottom: rem(12px);
  // }
  .not-first-use-tool-footer {
    margin-top: rem(24px);
    padding-bottom: rem(12px);
  }
}

[data-mode='dark'] {
  .dashboard-wrapper-container:has(.sideBarNavigation) {
    background-color: $color-background-default;
  }
}


.ap-container.itemFooterContent:has(.issuesContainer),
.ap-container.itemFooterContent:has(.dashboardContainer),
.ap-container.itemFooterContent:has(.settingsPage),
.ap-container.itemFooterContent:has(.issueDetailContainer) {
    @media screen and (max-width: 768px) {
      max-width: rem(642px);
    }
    @media screen and (min-width: 769px) and (max-width: 1550px) {
      max-width: calc(100% - rem(40px));
    }
    padding-left: 0;
    padding-right: 0;
}