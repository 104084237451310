@import 'node_modules/sass-rem/_rem.scss';
@import '../../styles/scss/variables';
.issuesContainer {
  .issueLabel:not(.inner-label) {
    font-weight: $font-weight-2;
    font-size: rem(36px);
    line-height: rem(42px);
    letter-spacing: rem(-0.4px);
    margin-top: $spacing-5 !important;
  }

  .tabsContainer {
    width: 100%;
    margin-top: rem(32px) !important;

    .ap-tabset-container {
      .ap-tabset-toggle-wrapper {
        .ap-tabset-underline {
          background-color: $color-background-hover-selected !important;
        }
      }
    }
  }

  .searchButtonContainer {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: rem(32px);
    .ap-buttons-wrapper .ap-menu-buttons:not(.download-excel-btn) .ap-button {
      width: rem(64px);
      padding-left: rem(12px);
      .Appkit4-icon.icon-circle-more-outline,
      .Appkit4-icon.icon-down-chevron-outline{
        margin-left: 0;
        margin-right: 0;
      }
    }
    .ap-buttons-wrapper.download-excel-btn {
      .ap-button-dropdown {
        right: $spacing-3;
      }
      .ap-checkbox-label{
        display: none;
      }
      .ap-option-item.active {
        &:not(:hover) {
          background-color: unset;
        }
      }
      .ap-option-item.active .ap-option-label {
        font-weight: $font-weight-1;
      }
    }
    .ap-buttons-wrapper .ap-button-dropdown {
      width: rem(224px);
    }
    .filterItemsPart {
      &.show {
        display: block;
      }
      &.hide {
        display: none;
      }
      width: rem(281px);
      box-shadow: $box-shadow-3;
      background-color: $color-background-container;
      position: absolute;
      top: $spacing-8;
      left: 0;
      padding: rem(20px);
      border-radius: $border-radius-3;
      z-index: 99;
      &-content {
        max-height: 450px;
        overflow-y: auto;
        scrollbar-width: none;
      }

      &-btnParts {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: rem(18px);

        Button {
          width: 48%;
        }
      }

      @media screen and (min-width: 1048px) {
        display: none;
      }
    }

    &-narrowFilter_iconPart {
      width: rem(40px);
      height: rem(40px);
      padding: $spacing-4;
      display: none;
      justify-content: center;
      align-items: center;
      margin-right: $spacing-5;
      cursor: pointer;

      .filterIcon-cls {
        color: $color-background-icon-primary;
        margin-right: 0 !important;
      }
      @media screen and (max-width: 1048px) {
        display: flex;
      }
    }

    .searchStyle {
      margin-right: $spacing-5;
    }
  }

  .narrowChoosedFilterItemsPart {
    display: none;
    align-items: center;
    margin-top: $spacing-4;
    .narrow-clearFilters-part {
      font-weight: $font-weight-2;
      font-size: rem(16px);
      line-height: rem(24px);
      letter-spacing: rem(-0.4px);
      color: $color-text-primary;
      cursor: pointer;
    }
    .narrowScreenFIlterDivider {
      width: rem(1px);
      height: rem(32px);
      opacity: $opacity-5;
      background-color: $color-text-heading;
      margin: 0 rem(14px) 0 rem(10px);
    }
    .narrow-selectedItems-part {
      .ap-tag:not(:first-child) {
        margin-left: $spacing-2;
      }
    }
    @media screen and (max-width: 1048px) {
      display: flex;
      .narrow-selectedItems-part {
        .ap-tag:not(:first-child) {
          margin-left: $spacing-2;
          margin-top: $spacing-2;
        }
      }
    }
  }

  .lastScanDatearea {
    width: 100%;
    display: flex;
    height: rem(24px);
    justify-content: space-between;
    margin-top: $spacing-7;

    .showMsgArea {
      font-size: rem(16px);
      line-height: rem(24px);
      letter-spacing: rem(-0.4px);
      color: $color-text-body;
      .total-area {
        font-weight: $font-weight-2;
      }
    }
    .lastScanPart {
      span {
        font-weight: $font-weight-1;
        font-size: rem(16px);
        line-height: rem(24px);
        letter-spacing: rem(-0.4px);
        color: $color-text-body;
      }
    }
    .lastScanPart span:first-child {
      color: $color-text-light;
    }
    .lastScanPart span:nth-child(2),
    .lastScanPart span:last-child {
      margin-left: $spacing-2;
    }
  }
    .ap-issue-list-title {
      font-size: $typography-text-size-5;
      line-height: $typography-line-height-5;
      color: $color-text-heading;
      font-weight: $font-weight-2;
      margin-top: $spacing-4;
    }
  .issueDashboardArea {
    margin-top: $spacing-4;
    width: 100%;
    display: flex;
    flex-direction: column;

    .issueDashboardContainer:first-child {
      margin-top: 0;
    }
  }

  .pageNationPart {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: $spacing-5;
    .showPart {
      display: flex;
      align-items: center;

      span {
        font-weight: $font-weight-1;
        font-size: rem(16px);
        line-height: rem(24px);
        letter-spacing: rem(-0.4px);
      }

      .pageDropdownPart {
        margin-left: $spacing-3;
        margin-right: $spacing-3;
        width: rem(80px);

        .ap-field-container {
          width: rem(76px);

          .ap-field-title-container {
            top: $spacing-2 !important;
          }

          .ap-field-wrapper {
            line-height: $spacing-32;
            height: $height-10 !important;
            border-radius: $spacing-2 !important;
          }

          &.hide-title input {
            margin: 0 0 0 $spacing-3 !important;
          }
        }

        .icon-down-chevron-outline {
          margin-top: $spacing-2 !important;
        }
      }
    }
    .pagePart {
    }
  }

  .disbaledStyle {
    opacity: 0.32;
    cursor: not-allowed !important;
  }
  .filterHeader {
    width: 100%;
    max-width: rem(298px);
    display: flex;
    justify-content: space-between;
    margin-top: $spacing-3;
    padding: $spacing-2 $spacing-3;

    span:first-child {
      font-weight: $font-weight-2;
      font-size: rem(20px);
      line-height: rem(24px);
      letter-spacing: rem(-0.4px);
      color: $color-text-heading;
    }
    span:last-child {
      font-weight: $font-weight-2;
      font-size: rem(16px);
      line-height: rem(24px);
      letter-spacing: rem(-0.4px);
      color: $color-text-primary;
      cursor: pointer;
    }

    @media screen and (max-width: 1048px) {
      display: none;
    }
  }

  .dataContent {
    max-width: rem(298px);
    display: flex;
    flex-direction: column;
    margin-top: $spacing-5;
    padding-bottom: 0.75rem;
    overflow: auto;
    scrollbar-width: none;
    // max-height: calc(100vh - rem(250px));
    max-height: calc(100vh - rem(152px));

    .statusPart {
    }
    .severityPart,
    .effortLevelPart,
    .testTypePart,
    .conformanceLevelPart,
    .wcagPart {
      margin-top: rem(20px);
    }

    @media screen and (max-width: 1048px) {
      display: none;
    }
  }

  .noIssueRowHeight {
    height: calc(100vh - rem(262px));
  }

  .noIssuePage {
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $color-background-container;
    border-radius: $border-radius-3;
    padding: $spacing-8 $spacing-3;
    box-shadow: $box-shadow-1;
    margin-top: $spacing-7;

    .imgPart {
      img {
        width: 100%;
        height: 100%;
      }
    }
    .boldTitlePart {
      font-weight: $font-weight-2;
      font-size: rem(24px);
      line-height: rem(24px);
      letter-spacing: rem(-0.4px);
      color: $color-text-heading;
      padding-top: $spacing-5;
      text-align: center;
    }

    .descPart {
      padding-top: $spacing-3;
      font-weight: $font-weight-1;
      font-size: rem(16px);
      max-width: rem(558px);
      line-height: rem(24px);
      letter-spacing: rem(-0.4px);
      color: $color-text-body;
      text-align: center;
      text-wrap: wrap;
    }
    .runAutoScanPart {
      padding-top: $spacing-5;
    }

    @media screen and (max-width: 1048px) {
    }
  }

  .ap-search-container {
    &:not(.show){
      padding: 0;
    }
  }

  .notNarrowScreenFiltersContent {
    position: relative;
    .notNarrowScreenFiltersArea {
      position: fixed;
      top: rem(80px);
      // top: rem(180px);
      overflow-y: auto;
      width: 18%;
    }
  }

  @media screen and (max-width: 1048px) {
    .col-9 {
      width: 100% !important;
      height: 100% !important;
    }
  }

  &-noIssuesToManagePart {
    margin-top: rem(32px);
  }
}

.issueContainerLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - rem(156px));
}
.outletcontainer:has(.issuesContainer) {
  height: 100vh;
}
.issuesContainer-noIssuesToManagePart {
  .home-projectContent-noProducts {
    max-width: unset;
  }
}
