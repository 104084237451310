@import 'node_modules/sass-rem/_rem.scss';
@import '../../../styles/scss/variables';

.editAccessRequirementsModal {
  .ap-drawer.ap-drawer-right{
    width: rem(720px);
  }
  .ap-drawer-body {
    padding-top: 0;
  }
  fieldset{
    padding: 0;
    // legend{
    //   display: none;
    // }
  }
  .autoPage-content-stepperContent-accessOptionPart-child-content .ap-accordion:first-child {
    margin-top: 0;
  }
  &-stepperContent {
    border-radius: $spacing-3;
    // padding: $spacing-6;
    background-color: $color-background-container;

    &-headers {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h2 {
        font: $typography-heading-m;
        font-weight: $font-weight-2;
        letter-spacing: rem(-0.4px);
        color: $color-text-heading;
      }

      span {
        font-size: rem(14px);
        line-height: rem(16px);
        font-weight: $font-weight-2;
        letter-spacing: rem(-0.4px);
        color: $color-text-light;
      }

      span::before {
        content: '';
        background-color: #c52b19;
        position: relative;
        left: rem(-10px);
        top: rem(-10px);
        display: inline-block;
        width: $spacing-2;
        height: $spacing-2;
        border-radius: $spacing-1;
      }
    }

    &-desc {
      font: $typography-body;
      font-weight: $font-weight-1;
      letter-spacing: rem(-0.4px);
      color: $color-text-body;
    }

    &-enterPageUrlPart {
      margin-top: $spacing-6;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-inputPart {
        width: 90%;
        padding-right: rem(10px);
        // textarea {
        //   height: auto;
        //   min-height: 20px !important;
        // }
      }
      &-addBtnPart {
        width: 10%;
      }
    }

    &-tablePart {
      margin-top: $spacing-6;
      &-showingPart {
      }
      &-table {
        position: relative;
        margin-top: $spacing-3;

        width: 100%;
        padding: var(--spacing-2) var(--spacing-3);
        box-shadow: inset 0 0 0 0.0625rem var(--color-background-border);
        border-radius: var(--border-radius-3);

        .ap-table {
          white-space: nowrap;
          overflow-x: auto;

          box-shadow: unset;
          border-radius: unset;
          background-color: unset;

          padding-bottom: 5px;
          padding-right: 0;

          &.ap-table-condensed tbody tr td {
            padding-top: $spacing-3 !important;
            padding-bottom: $spacing-3 !important;
          }

          table {
            a {
              font: $typography-body;
              font-weight: $font-weight-1;
              letter-spacing: rem(-0.4px);
              text-decoration: underline;
              color: $color-text-link-primary;
            }

            border-collapse: separate;
            border-spacing: unset !important;

            tbody tr:nth-child(odd) {
              td:nth-last-child(-n + 2) {
                background-color: $color-background-selected;
              }
            }
            tbody tr:nth-child(even) {
              td:nth-last-child(-n + 2) {
                background-color: $color-background-container;
              }
            }
            thead tr th:nth-child(1) {
              width: rem(180px);
            }

            tbody td {
              .ap-field-wrapper {
                height: rem(32px) !important;
              }
              input {
                text-align: left;
                // margin: 0 !important;
                margin: 0px 0px 0px $spacing-3 !important;
              }
            }

            thead tr th:nth-child(4),
            tbody tr td:nth-child(4) {
              position: sticky;
              right: 0;
              z-index: 10;
              width: rem(132px);

              span {
                cursor: pointer;
              }
            }

            thead tr th:nth-child(3),
            tbody tr td:nth-child(3) {
              position: sticky;
              right: rem(106px);
              z-index: 10;
              border-left: 1px solid $color-background-border;
              width: rem(94px);
            }
          }
        }

        &-line {
          position: absolute;
          z-index: 99;
          width: 1px;
          background-color: $color-background-border;
          right: rem(260px);
          top: rem(12px);
          bottom: rem(10px);
        }

        &-pendingColor {
          color: $color-background-warning;
        }

        td {
          vertical-align: middle;
        }
      }

      &-pagnation {
        display: flex;
        flex-direction: row-reverse;
      }
    }

    &-accessOptionPart {
      .ap-accordion-wrapper {
        padding-bottom: 0 !important;
      }

      // margin-top: $spacing-6;
      cursor: pointer;
      margin-top: rem(20px);
      &-option {
        &.clickedStyle {
          background-color: $color-background-triple-hover;
        }

        display: flex;
        background-color: $color-background-default;
        border-radius: $spacing-2;
        padding: $spacing-6;
        &-icon {
          margin-right: $spacing-3;
        }
        &-texts {
          &-title {
            h2 {
              font: $typography-heading-s;
              font-weight: $font-weight-2;
              letter-spacing: rem(-0.4px);
              color: $color-text-heading;
            }
          }
          &-desc {
            span {
              font: $typography-body;
              font-weight: $font-weight-1;
              letter-spacing: rem(-0.4px);
              color: $color-text-body;

              text-wrap: wrap;
            }
          }
        }

        width: 100%;
      }

      &-option:last-child {
        // margin-top: $spacing-6;
      }

      &-child {
        margin-top: $spacing-6;
        // margin-bottom: $spacing-6;
        padding: 0px 0px 0px rem(56px);
        display: none;

        &.show {
          display: block;
        }

        &-title {
          h3 {
            font: $typography-body;
            font-weight: $font-weight-2;
            letter-spacing: rem(-0.4px);
            color: $color-text-heading;
          }
          h3::after {
            content: '';
            background-color: #c52b19;
            display: inline-block;
            width: $spacing-2;
            height: $spacing-2;
            border-radius: $spacing-1;
            position: relative;
            bottom: $spacing-5;
            left: $spacing-1;
          }
        }
        &-content {
          .ap-accordion {
            background-color: $color-background-default !important;
            padding: $spacing-6;
          }

          .ap-accordion:nth-last-child(-n + 2) {
            margin-top: $spacing-4;
          }

          .content-clicked {
            background-color: $color-background-triple-hover !important;
          }
          .ap-accordion .ap-accordion-toggle .ap-accordion-title {
            height: rem(52px) !important;
            align-items: center;
          }

          margin-top: $spacing-3;
          &-item {
            cursor: pointer;

            &.itemClicked {
              background-color: $color-background-triple-hover;
            }

            border-radius: $spacing-2;
            padding: $spacing-6;
            background-color: $color-background-default;
            display: flex;

            flex-direction: column;

            &-icon {
              margin-right: $spacing-3;
            }
            &-texts {
              &-title {
                letter-spacing: rem(-0.4px);
                font: $typography-heading-s;
                font-weight: $font-weight-1;
                color: $color-text-body;
                font-size: rem(16px);
              }
              &-desc {
                font: $typography-body;
                color: $color-text-light;
                letter-spacing: rem(-0.4px);
                font-size: rem(14px);
              }
            }

            &-expandPart {
              width: 100%;

              display: none;
              &.show {
                display: block;
              }
              margin-top: $spacing-6;

              &-title {
                span {
                  font: $typography-body;
                  font-weight: $font-weight-2;
                  letter-spacing: rem(-0.4px);
                  color: $color-text-heading;
                }

                span::after {
                  content: '';
                  background-color: #c52b19;
                  display: inline-block;
                  width: $spacing-2;
                  height: $spacing-2;
                  border-radius: $spacing-1;
                  position: relative;
                  bottom: $spacing-3;
                  left: $spacing-1;
                }
              }

              &-idamOrIam {
                &-terms {
                  margin-top: $spacing-3;
                  margin-left: $spacing-3;
                  display: flex;
                  flex-direction: column;

                  &-single {
                    ul {
                      list-style-type: disc !important;
                    }

                    margin-top: $spacing-2;

                    span:nth-child(1) {
                      margin-right: $spacing-3;
                    }

                    span:nth-child(1),
                    span:nth-child(2),
                    a {
                      font: $typography-body;
                      font-weight: $font-weight-1;
                      color: $color-text-body;
                      letter-spacing: rem(-0.4px);
                    }

                    a {
                      text-decoration: underline;
                      color: $color-text-link-primary;
                    }

                    li {
                      display: flex;
                      margin-top: $spacing-2;
                      span {
                        margin-left: $spacing-3;
                      }
                    }
                  }
                }

                &-checkbox {
                  margin-top: $spacing-5;
                  margin-bottom: $spacing-1;

                  label {
                    font: $typography-body;
                    font-weight: $font-weight-2;
                    letter-spacing: rem(-0.4px);
                    color: $color-text-body;
                  }
                }
              }

              &-iamOrOther {
                margin-top: $spacing-3;
                margin-bottom: $spacing-2;

                &-desc {
                  span {
                    font: $typography-body;
                    font-weight: $font-weight-1;
                    letter-spacing: rem(-0.4px);
                    color: $color-text-body;
                  }
                }

                &-usrInfo {
                  margin-top: $spacing-3;
                  margin-right: $spacing-2;

                  display: flex;
                  justify-content: space-between;

                  .ap-field {
                    width: 50%;
                  }
                  .ap-field:first-child {
                    padding-right: $spacing-4;
                  }
                  .ap-field:last-child {
                    padding-left: $spacing-4;
                  }
                }
              }

              &-unsure {
                display: flex;
                flex-direction: column;
                div {
                  // margin-top: $spacing-3;

                  span {
                    font: $typography-body;
                    font-weight: $font-weight-1;
                    letter-spacing: rem(-0.4px);
                    color: $color-text-body;
                  }

                  span:last-child {
                    margin-left: $spacing-3;
                  }
                }
                .notification-panel {
                  display: flex;
                  grid-column-gap: $spacing-4;
                  column-gap: $spacing-3;
                  margin-top: $spacing-6;
                  padding: $spacing-4;
                  color: $color-text-body;
                  background-color: $color-background-container-alt;
                  line-height: 1.5rem;
                  border-radius: $border-radius-2;
              
                  .Appkit4-icon.icon-warning-fill.warning-icon {
                    color: $color-background-warning !important;
                  }
                }
              }
            }
          }
          &-item:nth-last-child(-n + 2) {
            margin-top: $spacing-4;
          }
        }
      }

      .ap-accordion:not(:last-child) {
        margin-bottom: 0;
      }

      .ap-accordion:hover {
        background-color: transparent;
      }

      .as-accordingItem2 {
        margin-top: $spacing-6 !important;
        .react-slidedown {
          display: none !important;
        }
      }

      .ap-accordion .ap-accordion-toggle,
      .ap-accordion .ap-accordion-content {
        padding: 0px;
      }

      .ap-accordion .ap-accordion-toggle .ap-accordion-title {
        height: rem(92px);
        align-items: center;
      }

      .ap-accordion .ap-accordion-toggle .ap-accordion-title {
        margin-right: 0;
      }
    }

    &-reviewPart {
      &-pagesToScan {
        margin-top: $spacing-6;

        &-title {
          span {
            font: $typography-body;
            font-weight: $font-weight-2;
            color: $color-text-heading;
            letter-spacing: rem(-0.4px);
          }
        }

        &-tablePart {
          margin-top: $spacing-3;
          &-showingPart {
            display: inline-block;
            margin-bottom: $spacing-2;
          }
          &-table {
            position: relative;
            margin-top: $spacing-3;

            width: 100%;
            padding: var(--spacing-2) var(--spacing-3);
            box-shadow: inset 0 0 0 0.0625rem var(--color-background-border);
            border-radius: var(--border-radius-3);

            .ap-table {
              white-space: nowrap;
              overflow-x: auto;

              box-shadow: unset;
              border-radius: unset;
              background-color: unset;

              padding-bottom: 5px;
              padding-right: 0;

              &.ap-table-condensed tbody tr td {
                padding-top: $spacing-3 !important;
                padding-bottom: $spacing-3 !important;
              }

              table {
                a {
                  font: $typography-body;
                  font-weight: $font-weight-1;
                  letter-spacing: rem(-0.4px);
                  text-decoration: underline;
                  color: $color-text-link-primary;
                }

                border-collapse: separate;
                border-spacing: unset !important;

                tbody tr:nth-child(odd) {
                  td:nth-last-child(-n + 2) {
                    background-color: $color-background-selected;
                  }
                }
                tbody tr:nth-child(even) {
                  td:nth-last-child(-n + 2) {
                    background-color: $color-background-container;
                  }
                }
                thead tr th:nth-child(1) {
                  width: rem(180px);
                }

                tbody td {
                  .ap-field-wrapper {
                    height: rem(32px) !important;
                  }
                  input {
                    text-align: left;
                    // margin: 0 !important;
                    margin: 0px 0px 0px $spacing-3 !important;
                  }
                }

                thead tr th:nth-child(4),
                tbody tr td:nth-child(4) {
                  position: sticky;
                  right: 0;
                  z-index: 10;
                  width: rem(132px);

                  span {
                    cursor: pointer;
                  }
                }

                thead tr th:nth-child(3),
                tbody tr td:nth-child(3) {
                  position: sticky;
                  right: rem(106px);
                  z-index: 10;
                  border-left: 1px solid $color-background-border;
                  width: rem(94px);
                }
              }
            }

            &-line {
              position: absolute;
              z-index: 99;
              width: 1px;
              background-color: $color-background-border;
              right: rem(260px);
              top: rem(12px);
              bottom: rem(10px);
            }

            &-pendingColor {
              color: $color-background-warning;
            }

            td {
              vertical-align: middle;
            }
          }

          &-pagnation {
            display: flex;
            flex-direction: row-reverse;
          }
        }
      }

      &-accessRequirements {
        margin-top: $spacing-6;

        &-title {
          font: $typography-body;
          font-weight: $font-weight-2;
          letter-spacing: rem(-0.4);
          color: $color-text-heading;
        }
        &-content {
          display: flex;

          margin-top: $spacing-3;
          border-radius: $spacing-2;
          padding: $spacing-6;
          background-color: $color-background-default;

          &-right {
            h2 {
              font: $typography-heading-s;
              font-weight: $font-weight-2;
              letter-spacing: rem(-0.4px);
              color: $color-text-heading;
            }
            span {
              font: $typography-body;
              font-weight: $font-weight-1;
              letter-spacing: rem(-0.4px);
              color: $color-text-body;
            }
          }
        }

        &-authTypePart {
          margin-top: $spacing-6;

          &-title {
            font: $typography-body;
            font-weight: $font-weight-1;
            color: $color-text-light;
          }
          &-content {
            display: flex;

            margin-top: $spacing-3;
            border-radius: $spacing-2;
            padding: $spacing-6;
            background-color: $color-background-default;

            &-right {
              h2 {
                font: $typography-heading-s;
                font-weight: $font-weight-2;
                letter-spacing: rem(-0.4px);
                color: $color-text-heading;
              }
              span {
                font: $typography-body;
                font-weight: $font-weight-1;
                letter-spacing: rem(-0.4px);
                color: $color-text-body;
              }
            }
          }
        }

        &-adminPart {
          margin-top: $spacing-6;

          &-title {
            font: $typography-body;
            font-weight: $font-weight-1;
            color: $color-text-light;
          }

          &-content {
            margin-top: $spacing-3;

            display: flex;
            justify-content: space-between;

            .ap-field {
              width: 50%;
            }
            .ap-field:first-child {
              padding-right: $spacing-4;
            }
            .ap-field:last-child {
              padding-left: $spacing-4;
            }
          }
        }
      }
    }

    &-needHelp {
      margin-top: $spacing-6;
      font: $typography-body;
      font-weight: $font-weight-1;
      letter-spacing: rem(-0.4px);
      color: $color-text-heading;
      span {
        margin-right: $spacing-2;
      }
      a {
        color: $color-text-link-primary;
        text-decoration: underline;
      }
    }
  }

  &-accRmts-icon {
    font-size: $spacing-7;
  }

  .ap-drawer-footer{
    display: flex;
    justify-content: space-between;
    .footer-required {
        color: $color-text-light;
        font-size: rem(14px);
        &::before {
          content: '';
          background-color: #c52b19;
          position: absolute;
          left: rem(20px);
          transform: translateY(rem(8px));
          display: inline-block;
          width: $spacing-2;
          height: $spacing-2;
          border-radius: $spacing-1;
        }
      }
      .editAccessRequirementsModal-footer-rf{
        margin-left: rem(8px);
      }
    .editAccessRequirementsModal-footer-rf+div{
      display: flex;
    }
  }
}

[data-mode='dark'] {
  .editAccessRequirementsModal .ap-drawer-footer .footer-required::before {
    background-color:  #DC7F76!important;
  }
}