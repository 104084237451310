@import 'node_modules/sass-rem/_rem.scss';
@import '../../styles/scss/variables';

.ap-page-content-for-licensing {
  .ap-component-wrapper {
    border-radius: $border-radius-3;
    margin-top: rem(24px);
  }

  .ap-page-list {
    border-radius: $border-radius-3;
    box-shadow: $box-shadow-1;
    background-color: $color-background-container;
    padding: rem(20px);

    //   transition: box-shadow background-color $transition-0;
    .notification-panel {
      display: flex;
      column-gap: $spacing-3;
      margin-top: $spacing-6;
      padding: $spacing-3 $spacing-4 $spacing-3 $spacing-4;
      color: $color-text-body;
      background-color: $color-background-hover-selected;
      line-height: $typography-line-height-4;
      border-radius: $border-radius-2; // 4px
    }

    .ap-page-list-section {
      ul{
        li{
          line-height: rem(24px);
        }
      }
      span.bold {
        line-height: rem(24px);
        font-weight: $font-weight-2;
      }

      &.without-margin-top {
        margin-top: 0 !important;
      }

      &:not(:last-child) {
        margin-top: $spacing-4;
        margin-bottom: $spacing-5;
      }
    }

    .ap-page-list-subtitle {
      color: $color-text-heading;
      font-weight: $font-weight-2;
      margin-bottom: $spacing-3;
    }

    .ap-page-list-content {
      color: $color-text-body;
      line-height: $typography-line-height-4;
      &:not(:last-child) {
        margin-bottom: $spacing-3;
      }
    }

    .ap-page-card-title {
      color: $color-text-heading;
      font-size: $typography-text-size-4;
      font-weight: $font-weight-2;
      line-height: $typography-line-height-3;

      &.with-margin-top {
        margin-top: rem(20px);
      }
    }

    .link-btn {
      width: rem(343px);
    }

    //   .ap-page-section-desc {
    //     font-size: $typography-text-size-3;
    //     line-height: $typography-line-height-3;
    //     font-weight: normal;
    //     font-stretch: normal;
    //     font-style: normal;
    //     letter-spacing: rem(-0.475px);
    //     color: $color-text-body;
    //     word-wrap: break-word;

    //     &.desc-1 {
    //       margin-top: rem(40px);
    //     }

    //     &.desc-2 {
    //       margin-top: $spacing-6; // 20px
    //       margin-bottom: rem(40px);
    //     }

    //     &.desc-3 {
    //       margin-bottom: $spacing-4; // 12px
    //     }

    //     &.notification-panel {
    //       display: flex;
    //       column-gap: $spacing-3;
    //       margin-top: $spacing-6;
    //       padding: $spacing-3 $spacing-4 $spacing-3 $spacing-4;
    //       color: $color-text-body;
    //       background-color: $color-background-hover-selected;
    //       line-height: $typography-line-height-4;
    //       border-radius: $border-radius-2; // 4px
    //     }

    //     &.figma-des {
    //       margin-top: $spacing-6; // 20px
    //     }
    //   }
  }

  .ap-page-card {
    border-radius: $border-radius-3;
    box-shadow: $box-shadow-1;
    background-color: $color-background-container;
    padding: $spacing-6;
    font-size: $typography-text-size-3;
    line-height: $typography-line-height-3;
    transition: box-shadow background-color $transition-0;

    .ap-page-section-item {
      margin-top: rem(4px);
    }

    .ap-page-list {
      &.within-page-card {
        box-shadow: none;
        padding-left: 0;
        padding-right: 0;
        border-radius: 0;
        border: none;
        padding-bottom: 0;
      }
    }

    &.clickable {
      display: flex;
      justify-content: space-between;
      cursor: pointer;

      &:hover {
        box-shadow: $box-shadow-2;

        .card-top-left,
        .card-right {
          color: $color-text-primary !important;
        }

        .ap-page-card-svg {
          color: $color-background-icon-primary !important;
        }

      }

      .card-top {
        display: grid;
        font-size: $typography-text-size-4;
        font-weight: $font-weight-2;
        line-height: $typography-line-height-3;

        .card-top-left {
          color: $color-text-heading;
          transition: color $transition-0;
        }

        .card-bottom-left {
          font: $typography-3 'PwC Helvetica Neue';
          color: $color-text-light;
        }


        .ap-page-card-svg {
          font: $typography-6 'PwC Helvetica Neue';
          width: 48px;
          height: 48px;
        }

        .ap-page-card-icon {
          width: rem(24px);
          height: rem(24px);
          color: $color-text-heading;
        }
      }

      .card-right {
        display: flex;
        column-gap: $spacing-2;
        color: $color-text-heading;
        transition: color $transition-0;
        width: $height-113;
        height: $height-113;
        line-height: $height-113;
        background-color: $color-background-default;
        border-radius: $border-radius-2;
      }

      .card-top-bottom {
        color: $color-text-body;
        margin-right: rem(-2px);
      }

      @media screen and (max-width: 1427px) {
        .card-top-bottom {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      @media screen and (max-width: 1023px) {
        row-gap: $spacing-2;

        .card-top {
          flex-direction: column-reverse;
          row-gap: $spacing-6;
        }
      }
    }

    &.card-margin {
      margin-top: $spacing-6; // 20px
      margin-bottom: $spacing-4; // 12px
    }

    .ap-page-card-title {
      color: $color-text-heading;
      font-size: $typography-text-size-4;
      font-weight: $font-weight-2;
      line-height: $typography-line-height-3;

      &.with-margin-top {
        margin-top: rem(20px);
      }

      &.with-margin-bottom {
        margin-bottom: rem(20px);
      }
    }
  }

  .ap-page-card-container {
    margin-top: $spacing-6;
  }

  .ap-page-section-desc {
    font-size: $typography-text-size-3;
    line-height: $typography-line-height-3;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: $letter-spacing-1;
    color: $color-text-body;
    word-wrap: break-word;
    .bold {
      line-height: rem(24px);
      font-weight: $font-weight-2;
    }
    &.with-margin-top {
      margin-top: rem(20px);
    }

    &.with-margin-bottom {
      margin-bottom: rem(20px);
    }

    &.notification {
      display: flex;
      column-gap: $spacing-4; // 12px
      margin-top: $spacing-6; // 20px
      padding: $spacing-4; // 12px
      color: $color-text-body;
      background-color: $color-background-hover-selected;
      line-height: $typography-line-height-4;
      border-radius: $border-radius-2; // 4px

      .notification-title {
        font-weight: $font-weight-2;
        color: $color-text-heading;
      }
    }

    &.desc-padding-top {
      padding-top: rem(24px);
    }

    &.desc-large-padding-top {
      padding-top: $spacing-36;
    }

    &.img-desc {
      max-width: rem(357px);
    }
  }
  // .ap-page-section.image-wrapper{
  //   height: rem(160px);
  //   position: relative;
  // }

  .ap-page-section-img {

    //   margin-top: $spacing-6;
    //       display: grid;
    //       grid-template-columns: repeat(2, minmax(0, 1fr));
    //       column-gap: $spacing-36;
    // //   height: rem(24px);
    //   line-height: rem(24px);
    //   padding: rem(20px);
    &.is-loading {
      padding: rem(20px);
      .loading-container {
        margin-top: 0!important;
      }
    }
    &.title-image {
      // background-image: url(../../assets/icons/titleImage.svg);
      // background-size: cover;
      // background-position: center;
      // width: 100%;
      // height: rem(159px);
      border-radius: rem(8px);
      height: rem(160px);
      padding-left: rem(60px);
      display: flex;
      align-items: center;
      font: $typography-heading-l;
      color: $neutral-01;
      margin-bottom: $spacing-6;
      background: url(../../assets/icons/licensing-header.svg) no-repeat;
      background-size: cover;
      // @media (max-width: 768px) {
        
        background-position-x: center;
        h1{
          font-weight: $font-weight-2;
        }
      // }
    }

    &.img-section-margin {
      margin-top: $spacing-36;
      margin-bottom: $spacing-6;
    }



    .licensing-main-info-country {
      background-color: $color-background-container-alt;
      padding: $spacing-6 0;
      border-radius: $border-radius-3;
      font: $typography-body;
      color: $color-text-body;
      margin-bottom: 0;
      display: grid;
      // grid-template-rows: repeat(8, 1fr); /* Creates 8 rows with equal height */
      grid-template-columns: repeat(5, 1fr);
      /* Creates 2 columns with equal width */
      grid-column-gap: 1px;
      /* Add a 1px gap between columns */
      .grid-item{
        img{
          margin-right: rem(4px);
        }
      }
      .grid-item:nth-child(-n+8) {
        grid-column: 1;

        /* Place the 1st-8th items in the first column */
        &.with-border {
          border-right: 1px solid $color-background-border;
        }
      }

      .grid-item:nth-child(n+9):nth-child(-n+16) {
        grid-column: 2;
        /* Place the 9th-16th items in the second column */
      }

      .grid-item:nth-child(n+17):nth-child(-n+24) {
        grid-column: 3;
        /* Place the 17th-24th items in the third column */
        border-left: 1px solid $color-background-border;
        padding-left: 16px;
      }

      .grid-item:nth-child(n+25):nth-child(-n+32) {
        grid-column: 4;
        /* Place the 25th-32th items in the forth column */
      }

      .grid-item:nth-child(n+33):nth-child(-n+40) {
        grid-column: 5;

        /* Place the 33th-40th items in the fifth column */
        &.with-border {
          border-right: 1px solid $color-background-border;
        }
      }

      @media screen and (min-width: 768px) and (max-width: 1080px) {
        grid-template-columns: repeat(2, 1fr);

        .grid-item:nth-child(-n+12) {
          grid-column: 1;

          /* Place the 1st-8th items in the first column */
          &.with-border {
            border-right: 1px solid $color-background-border;
          }
        }

        .grid-item:nth-child(n+9):nth-child(-n+12) {
          grid-column: 1;
          /* Place the 9th-16th items in the second column */
        }

        .grid-item:nth-child(n+13):nth-child(-n+25) {
          grid-column: 2;
          /* Place the 9th-16th items in the second column */
        }

        .grid-item:nth-child(n+17):nth-child(-n+24) {
          border-left: none;
          padding-left: 0;
        }

        .grid-item:nth-child(n+26):nth-child(-n+38) {
          grid-column: 3;
          /* Place the 17th-24th items in the third column */
          border-left: 1px solid $color-background-border;
          padding-left: 16px;
        }
      }

      .grid-item {
        margin-left: $spacing-6;
        padding-right: $spacing-6;
        display: flex;
        align-items: center;

        img {
          height: rem(16px);
          width: rem(16px);
        }

        // border-right: rem(1px) solid $color-background-border; /* Add a border to the right of each grid item */
      }
    }

    .ap-option-country-item {
      display: flex;
    }

    img {
      border-radius: $border-radius-2;
    }

    .img-clickable {
      cursor: pointer;
    }

  }

  .ap-page-section {
    background-color: $color-background-default;
    margin-top: $spacing-6;
    padding: rem(20px);
    border-radius: $border-radius-3;

    .ap-page-section-subtitle {
      font-size: $typography-text-size-3;
      line-height: $typography-line-height-4;
      font-weight: $font-weight-2;
    }

    &.no-margin-top {
      margin-top: 0;
      padding: 0;
    }
  }

  .ap-page-section-item {
    background-color: $color-background-default;
    margin-top: $spacing-6;
    padding: $spacing-3;
    border-radius: $border-radius-3;

    .ap-page-section-subtitle {
      font-size: $typography-text-size-3;
      line-height: $typography-line-height-4;
      font-weight: $font-weight-2;
    }

    &.no-margin-top {
      margin-top: 0;
    }

    .icon-arrow-increase-small-outline {
      color: $color-text-link-primary;
    }
  }

  .ap-component-wrapper.without-margin-bottom {
    margin-bottom: 0;
  }
}

.ap-page-content-for-licensing+.not-first-use-tool-footer.help-center {
  .footer-container {
    width: 100%;
  }
}

.ap-page-content-for-licensing {
  .ap-component-wrapper.without-margin-bottom {
    margin-bottom: 0;
  }

  .with-country {
    position: relative;

    .vertical-line {
      width: rem(1px); // Width of the vertical line
      height: calc(100% - rem(40px)); // Height of the vertical line
      background-color: #d1d1d1; // Color of the vertical line
      margin: 0 auto; // Center the line horizontally
      position: absolute;
      top: rem(20px);
      left: 50%;
    }
  }
}
.ap-container.licensing {
  margin-top: rem(48px);

  @media (min-width: 900px) {
    max-width: unset;
  }
}

@media screen and (min-width: 1441px) {
  .ap-container.licensing:has(.ap-page-content-for-licensing) {
      width: rem(1160px);
  }
}